import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "searchBar d-flex flex-column p-3 position-relative",
    class: {
      searchBar__expanded: _vm.isSearchBarExpanded
    }
  }, [_c('div', {
    staticClass: "btn_expand p-1 py-2 cursor-pointer position-absolute",
    on: {
      "click": _vm.handleClickExpandBtn
    }
  }, [_c('i', {
    staticClass: "fas",
    class: _vm.isSearchBarExpanded ? 'fa-chevron-left' : 'fa-chevron-right'
  })]), _c('b-form-input', {
    staticClass: "searchInput",
    attrs: {
      "size": "sm",
      "placeholder": "搜尋案場"
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('v-list', {
    staticClass: "mt-3",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "mandatory": "",
      "color": "acme_blue"
    },
    model: {
      value: _vm.selectedSite,
      callback: function callback($$v) {
        _vm.selectedSite = $$v;
      },
      expression: "selectedSite"
    }
  }, _vm._l(_vm.showSites, function (site, i) {
    return _c('v-list-item', {
      key: i,
      staticClass: "p-0",
      attrs: {
        "value": site.name
      }
    }, [_c('v-list-item-icon', {
      staticClass: "mr-0 ml-2"
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("far fa-building")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(site.name)
      }
    })], 1)], 1);
  }), 1)], 1)], 1), _c('div', {
    ref: "tabViewRef",
    staticClass: "card_view w-100",
    class: {
      card_view__expanded: !_vm.isSearchBarExpanded
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": "",
      "active-nav-item-class": "tab__active"
    }
  }, [_c('b-tab', {
    staticClass: "tab_view",
    attrs: {
      "title": "用電",
      "active": ""
    }
  }, [_c('div', {
    staticClass: "d-flex renew_time mx-4"
  }, [_c('div', {
    staticClass: "ml-auto"
  }, [_vm._v("最後更新:")]), _c('div', {
    staticClass: "text-right",
    staticStyle: {
      "width": "150px"
    }
  }, [_vm._v(" " + _vm._s(_vm.lastUpdateTime) + " ")])]), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.showData, function (d) {
    return _c('div', {
      key: d.title,
      staticClass: "demandInfo bg-white p-4 m-3 rounded-lg d-flex align-items-center"
    }, [_c('i', {
      staticClass: "demandInfo_icon",
      class: d.icon
    }), _c('div', {
      staticClass: "ml-3"
    }, [_c('div', {
      staticClass: "demandInfo_title"
    }, [_vm._v(_vm._s(d.title))]), _c('transition', {
      attrs: {
        "name": "dataNum-fade"
      }
    }, [_c('span', {
      key: _vm.lastUpdateTime,
      staticClass: "demandInfo_value"
    }, [_vm._v(_vm._s(d.value))])]), _c('span', {
      staticClass: "demandInfo_unit ml-3"
    }, [_vm._v(_vm._s(d.unit))])], 1)]);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.energyMinData, function (o) {
    return _c('div', {
      key: o.meterId,
      staticClass: "office bg-white m-3 rounded-lg d-flex flex-column overflow-hidden"
    }, [_c('div', {
      staticClass: "office_title"
    }, [_c('i', {
      staticClass: "far fa-building"
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(o.position))])]), _c('div', {
      staticClass: "d-flex p-3 align-items-center"
    }, [_c('div', {
      staticClass: "office_left"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("即時需量")]), _c('transition', {
      attrs: {
        "name": "dataNum-fade"
      }
    }, [_c('div', {
      key: _vm.lastUpdateTime,
      staticClass: "office_left_value mt-1"
    }, [_vm._v(" " + _vm._s(o.instant_demand) + " ")])]), _c('span', {
      staticClass: "office_left_unit"
    }, [_vm._v("kW")])], 1), _c('div', {
      staticClass: "ml-3 pl-4 pr-2 w-100 border-left d-flex flex-column justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex align-items-center my-1"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("最大需量")]), _c('transition', {
      attrs: {
        "name": "dataNum-fade"
      }
    }, [_c('span', {
      key: _vm.lastUpdateTime,
      staticClass: "office_right_value ml-auto"
    }, [_vm._v(" " + _vm._s(o.max_demand) + " ")])]), _c('span', {
      staticClass: "office_right_unit ml-2"
    }, [_vm._v("度")])], 1), _c('div', {
      staticClass: "d-flex align-items-center my-1"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("即時功率")]), _c('transition', {
      attrs: {
        "name": "dataNum-fade"
      }
    }, [_c('span', {
      key: _vm.lastUpdateTime,
      staticClass: "office_right_value ml-auto"
    }, [_vm._v(" " + _vm._s(o.P) + " ")])]), _c('span', {
      staticClass: "office_right_unit ml-2"
    }, [_vm._v("度")])], 1), _c('div', {
      staticClass: "d-flex align-items-center my-1"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("累積用電")]), _c('transition', {
      attrs: {
        "name": "dataNum-fade"
      }
    }, [_c('span', {
      key: _vm.lastUpdateTime,
      staticClass: "office_right_value ml-auto"
    }, [_vm._v(" " + _vm._s(o.kWh) + " ")])]), _c('span', {
      staticClass: "office_right_unit ml-2"
    }, [_vm._v("度")])], 1)])])]);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    ref: "pieChartRef",
    staticClass: "chart_left m-3 rounded-lg bg-white overflow-hidden"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("本月用電佔比")]), _c('highcharts', {
    attrs: {
      "id": "pieChartId",
      "options": _vm.pieChartOptions
    }
  })], 1), _c('div', {
    ref: "lineColumnChartRef",
    staticClass: "chart_right m-3 rounded-lg bg-white"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("本月用電需量")]), _c('highcharts', {
    staticClass: "px-3",
    attrs: {
      "id": "lineColumnChartId",
      "options": _vm.lineColumnChartOptions
    }
  })], 1)])]), _c('b-tab', {
    staticClass: "tab_view",
    attrs: {
      "title": "綠電"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.greenData, function (d) {
    return _c('div', {
      key: d.title,
      staticClass: "greenInfo bg-white p-4 m-3 rounded-lg"
    }, [_c('div', {
      staticClass: "ml-2"
    }, [_c('div', {
      staticClass: "greenInfo_title mb-2 d-flex align-items-center"
    }, [_vm._v(" " + _vm._s(d.title) + " "), _c('b-badge', {
      staticClass: "ml-auto",
      style: {
        background: d.badgeColor
      }
    }, [_vm._v(" 已達標 ")])], 1), _c('div', {
      staticClass: "d-flex mt-2"
    }, [_c('i', {
      staticClass: "greenInfo_icon mr-4",
      class: d.icon
    }), _c('div', [_c('div', {
      staticClass: "greenInfo_subtitle"
    }, [_vm._v("佔比")]), _c('span', {
      staticClass: "greenInfo_ratio"
    }, [_vm._v(_vm._s(d.ratio) + " %")])]), _c('div', {
      staticClass: "ml-5"
    }, [_c('div', {
      staticClass: "greenInfo_subtitle"
    }, [_vm._v("電量")]), _c('span', {
      staticClass: "greenInfo_value mr-2"
    }, [_vm._v(_vm._s(d.value))]), _c('span', {
      staticClass: "greenInfo_unit"
    }, [_vm._v("kWh")])])])])]);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    ref: "greenChartRef",
    staticClass: "m-3 rounded-lg bg-white greenChart1"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("綠電佔比趨勢")]), _c('highcharts', {
    staticClass: "px-3",
    attrs: {
      "id": "greenChartId",
      "options": _vm.greenChartOptions
    }
  })], 1)])]), _c('b-tab', {
    staticClass: "tab_view",
    attrs: {
      "title": "發電用電"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-1 mx-3 mt-3"
  }, [_c('date-picker', {
    staticClass: "mr-3",
    staticStyle: {
      "width": "160px"
    },
    attrs: {
      "type": "date",
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "placeholder": "請選擇日期",
      "disabled-date": _vm.disabledDateAfterToday,
      "prefix-class": "xmx"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn(_ref) {
        var emit = _ref.emit;
        return [_c('button', {
          staticClass: "xmx-btn xmx-btn-text px-2 py-1 border",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              emit(new Date());
            }
          }
        }, [_vm._v(" 今天 ")])];
      }
    }]),
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "acme_blue",
      "size": "sm"
    }
  }, [_vm._v(" 查詢 ")])], 1), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.powerData, function (p) {
    return _c('div', {
      key: p.title,
      staticClass: "powerInfo bg-white p-4 m-3 rounded-lg"
    }, [_c('div', {
      staticClass: "powerInfo_title"
    }, [_vm._v(_vm._s(p.title))]), _c('b-progress', {
      staticClass: "mt-3 mb-2 rounded-lg",
      attrs: {
        "show-progress": "",
        "max": p.greenValue + p.utilityValue,
        "height": "36px",
        "precision": 1
      }
    }, [_c('b-progress-bar', {
      staticClass: "p-3 text-left font-weight-bold",
      staticStyle: {
        "font-size": "20px"
      },
      style: {
        backgroundColor: p.greenColor
      },
      attrs: {
        "value": p.greenValue,
        "label": "".concat((p.greenValue / (p.greenValue + p.utilityValue) * 100).toFixed(1), " %")
      }
    }), _c('b-progress-bar', {
      staticClass: "p-3 text-right font-weight-bold",
      staticStyle: {
        "font-size": "20px"
      },
      style: {
        backgroundColor: p.utilityColor
      },
      attrs: {
        "value": p.utilityValue,
        "label": "".concat((p.utilityValue / (p.greenValue + p.utilityValue) * 100).toFixed(1), " %")
      }
    })], 1), _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('div', [_c('span', {
      staticClass: "powerInfo_text"
    }, [_vm._v(_vm._s(p.greenTitle))]), _c('span', {
      staticClass: "powerInfo_value mx-2",
      style: {
        color: p.greenColor
      }
    }, [_vm._v(_vm._s(p.greenValue))]), _c('span', {
      staticClass: "powerInfo_unit"
    }, [_vm._v("kWh")])]), _c('div', [_c('span', {
      staticClass: "powerInfo_text"
    }, [_vm._v(_vm._s(p.utilityTitle))]), _c('span', {
      staticClass: "powerInfo_value mx-2",
      style: {
        color: p.utilityColor
      }
    }, [_vm._v(_vm._s(p.utilityValue))]), _c('span', {
      staticClass: "powerInfo_unit"
    }, [_vm._v("kWh")])])])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    ref: "powerChartRef",
    staticClass: "w-100 m-3 rounded-lg bg-white"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("發電用電趨勢")]), _c('highcharts', {
    staticClass: "px-3",
    attrs: {
      "id": "powerChartId",
      "options": _vm.powerChartOptions
    }
  })], 1)])])], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };