// --------------------------- 用電 ---------------------------
// ------------------------------------------------------------

// const pieColors = () => {
//   const colors = []
//   // var base = Highcharts.getOptions().colors[0]
//   // const base = '#8de9f7'
//   const base = '#72d7fc'

//   for (let i = 0; i <script 10; i += 1) {
//     // Start out with a darkened base color (negative brighten), and end
//     // up with a much brighter color
//     colors.push(
//       Highcharts.color(base)
//         .brighten((i - 3) / 7)
//         .get()
//     )
//   }
//   return colors
// }

export const pieChartSettings = {
  chart: {
    type: 'pie',
    height: 300
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  subtitle: { text: '' },
  tooltip: {
    style: {
      color: '#414141',
      fontSize: '14px',
      fontWeight: 'bold'
    },
    // valueSuffix: ' %', // pointFormat 有 point.y 才會出現
    hideDelay: 0,
    padding: 15,
    useHTML: true,
    headerFormat: '<div class="mb-1 font-weight-normal">{point.key}</div>',
    pointFormat:
      '<span style="color:{point.color}">\u25CF</span> <b style="letter-spacing: 0.5px">{series.name}: </b> <b style="color: #2b2b2b">{point.value:.2f}</b><span> kWh</span>'
  },
  colors: ['#ff8f5b', '#30bfdb', '#d63987', '#30db99'],
  // colors: ['#F8C4B4', '#f6e1ea', '#B8E8FC', '#BCE29E'],
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -60,
    y: 30,
    // itemDistance: 80,
    margin: -150,
    itemMarginBottom: 20
    // padding: 18
    // symbolHeight: 50
  },

  plotOptions: {
    pie: {
      // allowPointSelect: false,
      // cursor: 'pointer',
      // colors: pieColors(),

      innerSize: '52%',
      showInLegend: true,
      dataLabels: {
        enabled: true,
        format: '<span>{point.percentage:.1f} %</span>',
        // format:
        //   '<span>{point.name}</span><br><span style="font-size: 1.2em">{point.percentage:.1f} %</span>',
        distance: -20,
        filter: {
          property: 'percentage',
          operator: '>',
          value: 4 // 大於才顯示 label
        },
        // style: {
        //   color: 'red',
        //   backgroundColor: 'white'
        // }
        style: {
          // textOutline: 'none', // 移除文字白邊
          color: '#2b2b2b', // 設定文字顏色
          fontSize: '14px' // 自訂文字大小
        }
        // backgroundColor: 'rgba(255, 255, 255, 0.8)', // 背景顏色 (半透明白色)
        // borderRadius: 3, // 圓角邊框
        // paddingTop: 2, // 內邊距
        // paddingBottom: 0
      },
      size: '90%', // chart size

      stickyTracking: false,
      states: {
        hover: {
          brightness: 0,
          halo: {
            // 外暈光樣式
            size: 0
            // opacity: 0.8
          }
        },
        normal: {
          animation: {
            duration: 200
            // 反白變至正常的動畫時間
          }
        }
        // inactive: {
        //   enabled: false // 滑鼠移至圖表某類時，其他類不變淡
        // }
      }
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          subtitle: {
            y: -3
          },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            x: 0,
            y: 0,
            margin: 0,
            itemMarginBottom: 10
          }
        }
      }
    ]
  }
}

export const lineColumnChartSettings = {
  chart: {
    height: 300,
    marginTop: 45,
    marginBottom: 90,
    spacingBottom: 15
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  tooltip: {
    style: {
      color: '#414141',
      fontSize: '14px',
      fontWeight: 'bold'
    },
    shared: true,
    valueSuffix: ' kW',
    distance: 30,
    followPointer: true,
    hideDelay: 0,
    padding: 15,
    useHTML: true,
    headerFormat: '<div class="mb-1 font-weight-normal">{point.key}</div>',
    pointFormat:
      '<span style="color:{point.color}">\u25CF</span> <b style="letter-spacing: 0.5px">{series.name}: </b> <b style="color:{point.color}">{point.y}</b><br/>'
  },
  xAxis: {
    crosshair: true,
    categories: Array.from(
      { length: 31 },
      (_, idx) => `12/${String(idx + 1).padStart(2, '0')}`
    )
  },
  yAxis: {
    title: {
      text: '用電需量 （kW）',
      // offset: 50,
      rotation: 0,
      x: 65,
      style: {
        fontSize: '0.8rem',
        verticalAlign: 'middle',
        writingMode: 'vertical-rl',
        '-webkit-writing-mode': 'vertical-rl',
        '-ms-writing-mode': 'tb-rl'
      }
    }
  },
  colors: ['#e67845', '#6588a9'],
  plotOptions: {
    line: {
      marker: {
        enabled: false // 線上無圓點
      },
      stickyTracking: false,
      states: {
        hover: {
          enabled: false // 不產生圓點
        }
      }
    },
    column: {
      stickyTracking: false,
      states: {
        hover: {
          brightness: 0.2
        },
        inactive: {
          enabled: false // 滑鼠移至圖表某類時，其他類不變淡
        }
      }
    }
  }
}

// --------------------------- 綠電 ---------------------------
// ------------------------------------------------------------
export const greenChartSettings = {
  chart: {
    // height: 300,
    marginTop: 45,
    marginBottom: 90,
    spacingBottom: 15
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  tooltip: {
    // backgroundColor: '#ffffff', // 完全不透明的白色背景
    style: {
      color: '#414141',
      fontSize: '14px',
      fontWeight: 'bold'
    },
    shared: true,
    valueSuffix: ' kWh',
    distance: 30,
    followPointer: true,
    hideDelay: 0,
    padding: 15,
    useHTML: true,
    headerFormat: '<div class="mb-1 font-weight-normal">{point.key}</div>',
    pointFormat:
      '<span style="color:{point.color}">\u25CF</span> <b style="letter-spacing: 0.5px">{series.name}: </b> <b style="color:{point.color}">{point.y}</b><br/>'
  },
  xAxis: {
    // offset: 10, // x軸上方空間,
    crosshair: true,
    tickWidth: 1, // 刻度

    categories: Array.from(
      { length: 31 },
      (_, idx) => `12/${String(idx + 1).padStart(2, '0')}`
    )
  },
  yAxis: {
    title: {
      text: '電量（度）',
      rotation: 0,
      x: 35,
      style: {
        fontSize: '0.8rem',
        verticalAlign: 'middle',
        writingMode: 'vertical-rl',
        '-webkit-writing-mode': 'vertical-rl',
        '-ms-writing-mode': 'tb-rl'
      }
    }
  },
  colors: ['#ff8f5b', '#30db99', '#1298b3'],
  plotOptions: {
    line: {
      marker: {
        // enabled: false // 線上無圓點
      },
      stickyTracking: false,
      states: {
        hover: {
          // enabled: false // 不產生圓點
        }
      }
    },
    column: {
      stacking: 'normal',
      // borderWidth: 0,
      stickyTracking: false,
      states: {
        hover: {
          // brightness: 0.2
        },
        inactive: {
          // enabled: false // 滑鼠移至圖表某類時，其他類不變淡
        }
      }
    }
  }
}

// ------------------------- 發電用電-------------------------
// ----------------------------------------------------------
export const powerChartSettings = {
  chart: {
    type: 'areaspline',
    marginTop: 45,
    marginBottom: 90,
    spacingBottom: 15
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  tooltip: {
    // backgroundColor: '#ffffff', // 完全不透明的白色背景
    style: {
      color: '#414141',
      fontSize: '14px',
      fontWeight: 'bold'
    },
    shared: true,
    valueSuffix: ' kWh',
    distance: 30,
    followPointer: true,
    hideDelay: 0,
    padding: 15,
    useHTML: true,
    // headerFormat: '<div class="mb-1 font-weight-normal">{point.key}</div>',
    // pointFormat:
    //   '<span style="color:{point.color}">\u25CF</span> <b style="letter-spacing: 0.5px">{series.name}: </b> <b style="color:{point.color}">{point.y}</b><br/>',
    // footerFormat: '<div class="mb-1 font-weight-normal">{point.key}</div>'

    // TODO: 加總發電量

    formatter: function () {
      console.log(this.points)
      const title = `<div class="font-weight-normal">${this.points[0].key}</div>`

      // const list = this.points.reduce((accu, curr, idx) => {
      //   console.log(accu, curr)
      //   if (curr.series.name === '綠電自發自用' || curr.series.name === '餘電躉售') {
      //     return accu + curr.y
      //   }
      //   return accu
      // }, 0)
      // console.log(list)

      // return list + ''

      const list = this.points.reduce((s, point) => {
        console.log(s, point)
        return (
          s +
          `<span style="color:${point.color}">\u25CF</span> <b style="letter-spacing: 0.5px">${point.series.name}: </b><b style="color:${point.color}">${point.y}</b> <span style="font-size: 0.9em">kWh</span><br/>`
        )
      }, '')

      const totalPower = this.points.reduce((accu, curr, idx) => {
        console.log(accu, curr)
        if (
          curr.series.name === '綠電自發自用' ||
          curr.series.name === '餘電躉售'
        ) {
          return accu + curr.y
        }
        return accu
      }, 0)

      const totalPowerText = `( 發電量: ${totalPower}<span style="font-size: 0.9em"> kWh</span> )`

      return title + list + totalPowerText
    }
  },
  xAxis: {
    offset: 10, // x軸上方空間,
    // crosshair: true,
    tickmarkPlacement: 'on', // 將刻度標記對齊資料點
    crosshair: {
      color: '#ddd', // 設置線條的顏色
      width: 1, // 線條寬度
      dashStyle: 'Solid', // 線條樣式
      zIndex: 3
    },
    tickWidth: 1, // 刻度

    categories: Array.from(
      { length: 24 },
      (_, idx) => `${String(idx + 1).padStart(2, '0')}:00`
    )
    // plotBands: [{
    //   from: 5,
    //   to: 9,
    //   color: '#565',
    //   label: {
    //     text: '夜間',
    //     style: {
    //       color: '#56552'
    //     }
    //   }
    // }]
  },
  yAxis: {
    title: {
      text: '電量（度）',
      rotation: 0,
      x: 35,
      style: {
        fontSize: '0.8rem',
        verticalAlign: 'middle',
        writingMode: 'vertical-rl',
        '-webkit-writing-mode': 'vertical-rl',
        '-ms-writing-mode': 'tb-rl'
      }
    }
  },
  colors: ['#ff8f5b', '#30db99', '#1298b3'],
  plotOptions: {
    areaspline: {
      fillOpacity: 0.4,
      marker: {
        enabled: false, // 不顯示線上的點
        // symbol: 'circle',
        radius: 2 // hover 顯示的點大小
        // states: {
        //   hover: {
        //     enabled: true
        //   }
        // }
      },
      trackByArea: true,
      stickyTracking: false,
      states: {
        inactive: {
          enabled: false // 滑鼠移至圖表某類時，其他類不變淡
        }
      }
    }
  }
}
